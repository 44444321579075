<template>
  <v-footer
    id="home-footer"
    color="secondary"
    light
    min-height="72"
  >
    <v-container>
      <v-row>
        <div class="col-12 col-sm-6 col-md-8">
          <div class="text-md-left text-sm-center">
            <base-btn
              :tile="false"
              color="secondary"
              to="/agb"
              class="primary--text"
            >
              AGB
            </base-btn>
            <base-btn
              :tile="false"
              color="secondary"
              to="/impressum"
              class="primary--text"
            >
              Impressum
            </base-btn>
            <!--div
              style="flex: 0 0 auto"
              class="primary--text"
            >
              Copyright &copy; {{ new Date().getFullYear() }} Breaking School
            </div-->
          </div>
        </div>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      links: [
        'Impressum',
        'AGB',
      ],
    }),
  }
</script>
<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
